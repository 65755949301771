import { useEffect } from "react";
import { useAppSelector } from "../../app/hooks";
import Case from "./Case";
import "./Cases.css";
import { selectCases } from "./casesSlice";
import { useTranslation } from "react-i18next";
import Player from "./player";

const Cases: React.FC = () => {
	const { t } = useTranslation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const cases = useAppSelector(selectCases);

	return (
		<>
            <Player
                src={`https://player.vimeo.com/video/787335899?h=3812de678a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
            />
			<section className="cases">
				<h4>/{t("ourWorks")}</h4>
				<ul>
					{cases.length > 0 &&
						cases.map((item) => (
							<Case project={item} key={`case-list-item-${item.id}`} />
						))}
				</ul>
			</section>
		</>
	);
};

export default Cases;
