import GTranslateIcon from "@mui/icons-material/GTranslate";
import Fab from "@mui/material/Fab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import i18n from '../../locale/i18n';

const LocaleSwitch: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const updateLocalLang = (lang: string) => {
        localStorage.setItem('lang', lang);
    }

	return (
		<>
			<Fab
				size="medium"
				style={{
					position: `fixed`,
					right: `2vw`,
                    bottom: `2vh`,
                    borderRadius: `0px`
				}}
                color="primary"
                onClick={(e) => {
                    setIsOpen(!isOpen)
                    setAnchorEl(e.currentTarget)
                }}
			>
				<GTranslateIcon />
			</Fab>
            <Menu
                id='locale-menu'
                open={isOpen}
                onClose={() => setIsOpen(false)}
                anchorEl={anchorEl}
            >
                <MenuItem
                    onClick={() => {
                        setIsOpen(false)
                        updateLocalLang('en')
                        i18n.changeLanguage('en')
                    }}
                >English</MenuItem>
                <MenuItem
                    onClick={() => {
                        setIsOpen(false)
                        updateLocalLang('zh_CN')
                        i18n.changeLanguage('zh_CN')
                    }}
                >中文</MenuItem>
            </Menu>
		</>
	);
};

export default LocaleSwitch;
