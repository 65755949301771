import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import "./Case.css";
import { Case as CaseType } from "./CaseType";
import Image from "./Image";

interface Props {
	project: CaseType;
}

const Case: React.FC<Props> = (props) => {
	const navigate = useNavigate();
	const { project } = props;

	return (
			<li
				style={{ minHeight: window.innerHeight / 2 }}
				className="case"
				onClick={() => navigate(`/cases/${project.id}`)}
        >
            <Fade direction="down" cascade>
				<div>
					<h2>{project.title}</h2>
				</div>
                <Image project={project} />
                </Fade>
			</li>
		
	);
};

export default Case;
