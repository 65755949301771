export const CN = {
    ourWorks: '我们的作品',
    aboutUs: `关于我们`,
    hello: `你好！`,
    ceo: `创办人`,
    contactUs: `联络我们`,
    intro: `我们很荣幸为你介绍我们這一家专业、年轻、充满活力的活动策划、舞台和展览设计公司。`,
    background: `我们成立于2017年，我们致力为客户提供无缝服务，从设计理念到舞台、活动和展览的装饰布置方案，再到企业品牌和营销宣传的各类型服务。`,
    Digitally: `线上联络`,
    Physically: `地址`,
    Socially: `社交媒体`,
    Client: `客户`,
    Venue: `场地`
}