import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './Header.css';
import Logo from '../../assets/logo.svg';
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
	const {t} = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();

	return (
		<header className={`header${isOpen ? " open" : ""}`}>
			<div
				className={`header-backdrop${isOpen ? " open" : ""}`}
				onClick={() => {
					setIsOpen(!isOpen);
				}}
			>
			</div>
			<p className="copyright">
				<span style={{ fontFamily: "sans-serif" }}>&#169;</span>{" "}
				{new Date().getFullYear()}. NAK Concept
				{/* <br/><br/>
				<a href="https://www.bentsang.co.uk" target="_blank" rel="noreferrer" className="mobile-ref">
				Designed by<br/>TSANGHOILUN
				</a> */}
			</p>
			<img
				src={Logo}
				alt='NAK Concent Ltd.'
				className={`logo ${isOpen ? `` : ``}`}
				style={{ cursor: 'pointer' }}
				onClick={() => {
					setIsOpen(false)
					navigate(`/`)
				}}
			/>
			<div>
				<Link
					to="/"
					onClick={() => {
						isOpen ? setIsOpen(false) : setIsOpen(true);
					}}
				>
					{t('ourWorks')}
				</Link>
				<Link
					to="/about"
					onClick={() => {
						isOpen ? setIsOpen(false) : setIsOpen(true);
					}}
				>
					{t('aboutUs')}
				</Link>
				<Link
					to="/contact"
					onClick={() => {
						isOpen ? setIsOpen(false) : setIsOpen(true);
					}}
				>
					{t('contactUs')}
				</Link>
			</div>
			<div
				className="navToggle"
				onClick={() => {
					isOpen ? setIsOpen(false) : setIsOpen(true);
				}}
			>
				<svg
					width="114"
					height="64"
					viewBox="0 0 114 64"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect width="114" height="12" fill="white" />
					<rect y="52" width="114" height="12" fill="white" />
				</svg>
			</div>
		</header>
	);
};

export default Header;
