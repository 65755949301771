import { useState, useMemo } from "react";
import { Case } from "./CaseType";
import "./Image.css";

interface Props {
	project: Case;
}

const Image: React.FC<Props> = (props) => {
	const { project } = props;
	const liTop = useMemo(() => -Math.ceil(Math.random() * 24) + "px", []);
	const liLeft = useMemo(() => Math.ceil(Math.random() * 48) - 24 + "px", []);
	const [isLoad, setIsLoad] = useState(false);
	// const wh = window.innerHeight;
	var randomColor = require("randomcolor");

	return (
		<div style={{ left: liLeft, top: liTop }} className='case-image-container' >
			{
				!isLoad && null
				// <Skeleton
				//     style={{display: isLoad}}
				//     height={wh/3}
				// />
			}
			<img
				className="thumb"
				src={project.photos[0].url}
				onLoad={() => setIsLoad(true)}
				alt={project.title}
			/>
			{project.categories.length > 0 &&
				project.categories.map((item) => (
					<div className="tags" key={`case-item-${project.id}tag-item-${item.id}`}>
						<div style={{ background: randomColor({luminosity: 'dark'}) }}></div>
						<span>{item.name}</span>
					</div>
				))}
		</div>
	);
};

export default Image;
