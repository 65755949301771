import { ThemeProvider, createTheme } from "@mui/material/styles";
import { request } from "graphql-request";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import { store } from "./app/store";
import About from "./features/about/About";
import CaseDetails from "./features/case/CaseDetails";
import Cases from "./features/case/Cases";
import { setCases } from "./features/case/casesSlice";
import Contact from "./features/contact/Contact";
import Header from "./features/global/Header";
import LocaleSwitch from "./features/global/LocaleSwitch";
import SideTag from "./features/global/SideTag";
import i18n from "./locale/i18n";

const theme = createTheme({
	palette: {
		primary: {
			main: `#000000`,
		},
	},
});

const App: React.FC = () => {
	const [isLoaded, setIsLoaded] = useState(false);
	const translate = useTranslation();

	useEffect(() => {
		console.log(translate.i18n.language);
		const getContent = async () => {
			const { projects } = await request<any>(
				String(process.env.REACT_APP_GRAPHCMS_API),
				`{
					projects(orderBy: createdAt_DESC, locales: ${i18n.language}) {
						id
						title
						categories {
							name
							id
						}
						client {
							id
							name
						}
						publishedAt
						createdAt
						venue
						photos(locales: en) {
							id
							url(transformation: {image: {resize: {fit: max, height: 1000, width: 1000}}})
						}
					}
				}`
			);
			store.dispatch(setCases(projects));
			setIsLoaded(true);
		};
		getContent();
	}, [translate]);

	return isLoaded ? (
		<ThemeProvider theme={theme}>
			<main className="App">
				<Router>
					<Header />
					<SideTag />
					<Routes>
						<Route index element={<Cases />} />
						<Route path={`cases`}>
							<Route path=":id" element={<CaseDetails />} />
						</Route>
						<Route path="/about" element={<About />} />
						<Route path="/contact" element={<Contact />} />
					</Routes>
				</Router>
				<LocaleSwitch />
				{/* <Footer /> */}
			</main>
		</ThemeProvider>
	) : null;
};

export default App;
