export const EN = {
    ourWorks: 'Our Works',
    aboutUs: `About Us`,
    hello: `Hello!`,
    ceo: `Founder & Director`,
    contactUs: `Contact Us`,
    intro: `We are glad to introduce ourselves as a professional, young and dynamic event, stage and exhibition design company.`,
    background: `Established in 2017, we provide seamless service to our clients, from design concepts to hardware production and decoration solutions for stage, event and exhibition, to corporate branding and marketing communications.`,
    Digitally: `Digitally`,
    Physically: `Physically`,
    Socially: `Socially`,
    Client: `Client`,
    Venue: `Venue`
}