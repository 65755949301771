import { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { FaFacebookF, FaTwitter } from 'react-icons/fa';


const Contact: React.FC = () => {
    const {t} = useTranslation();
    useEffect(() => {
		window.scrollTo(0, 0);
    }, [])
    
    return (
        <section className="neolist contact-page">
            <h4>/{t(`contactUs`)}</h4>
            <ul>
                <li className='case'>
                    <Fade direction='down'>
                        <h2>{t(`Digitally`)}</h2>
                        <br/>
                        <div className=''>
                            <a href='tel:+85261947501'>Tel: +852 61947501</a><br></br>
                            <a href='mailto:wai.fan@nakconcept.com'>Email: wai.fan@nakconcept.com</a>
                        </div>
                    </Fade>
                </li>

                <li className='case'>
                    <Fade direction='down'>
                        <h2>{t(`Physically`)}</h2>
                        <br/>
                        <div className=''>
                            <a href='https://goo.gl/maps/yQFfpvUmqVeZtrqy8' target='_blank' rel="noreferrer">Room 501, 5/F, Bonham Centre<br/>79 Bonham Strand<br/>Sheung Wan<br/>HK</a>
                        </div>
                    </Fade>
                </li>

                <li className='case'>
                    <Fade direction='down'>
                        <h2>{t(`Socially`)}</h2>
                        <br/>
                        <div className=''>
                            <a href='/'><FaFacebookF/>: fan.k.wai</a>
                            <br></br>
                            <a href='/'><FaTwitter/>: #nakconcept</a>
                            <br></br>
                        </div>
                    </Fade>
                </li>
            </ul>
        </section>
    )
}

export default Contact;