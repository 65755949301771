import { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import FrontImg from "../../assets/NAK-front.jpg";
import { useTranslation } from "react-i18next";

import "./About.css";
import Player from "../case/player";

const About: React.FC = () => {
	const { t } = useTranslation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Player
				src={`https://player.vimeo.com/video/787339304?h=ae66974814&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
            />
            <Player
				src={`https://player.vimeo.com/video/787339850?h=e4b77233d7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
			/>
			<section className="neolist">
				{/* <Logo /> */}
				<h4>/{t("aboutUs")}</h4>
				<ul>
					<li className="case">
						<Fade direction="down">
							<h2>{t("hello")}</h2>
							<br></br>
							<br></br>
							<h4>{t("intro")}</h4>
						</Fade>
					</li>

					<li className="case">
						<Fade direction="down">
							<img src={FrontImg} alt="welcome" style={{ maxWidth: `100%` }} />
						</Fade>
					</li>

					<li className="case">
						<Fade direction="down">
							<p className="box">{t("background")}</p>
						</Fade>
					</li>

					<li className="case">
						<Fade direction="down">
							<h2>{t("ceo")}</h2>
							<br></br>
							<br></br>
							<h4>FAN KWOK WAI</h4>
							<div className="">
								<a href="tel:+85261947501">T: +852 6194 7501</a>
								<br></br>
								<a href="mailto:EMAIL: wai.fan@nakconcept.com">
									E: wai.fan@nakconcept.com
								</a>
							</div>
						</Fade>
					</li>
				</ul>
			</section>
		</>
	);
};

export default About;
