import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectCase, selectPrevNext } from "./casesSlice";
import { Case } from "./CaseType";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Slider from "react-slick";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

import "./CaseDetails.css";
import { useTranslation } from "react-i18next";

const CaseDetails: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const project: Case | undefined = useAppSelector(selectCase(String(id)));
	const { t } = useTranslation();
	const nav: { prev: Case | null; next: Case | null } | null = useAppSelector(
		selectPrevNext(String(id))
	);
	const navigate = useNavigate();

	const slickSettings: any = {
		dots: true,
		infinite: true,
		speed: 500,
		slideToShow: 1,
		slideToScroll: 1,
	};

	return !project ? null : (
		<section className="case-details-section">
			<div className="case-details-details">
				<div style={{padding: `36px`}} >
					<h2>{project.title}</h2>
					{project.categories.length > 0 && (
						<Stack direction="row" spacing={1}>
							{project.categories.map((item) => (
								<Chip
									key={`project-${project.id}-cat-item-${item.id}`}
									label={item.name}
								/>
							))}
						</Stack>
					)}
					<br />
					<div>
						{t(`Client`)}: {project.client.name}
						<br />
						{t(`Venue`)}: {project.venue}
					</div>
					<br />
                </div>
                <div className="case-details-nav">
						<div>
							{nav && nav.prev && (
								<button
									onClick={() => navigate(`/cases/${nav.prev?.id}`)}
                            >
                                <MdOutlineKeyboardArrowLeft className="arrows" size={24} />
                                <span>{`${nav.prev.title}`}</span>
                                </button>
							)}
						</div>
						<div>
							{nav && nav.next && (
								<button
									onClick={() => navigate(`/cases/${nav.next?.id}`)}
                            >
                                <span>{`${nav.next.title}`}</span>
                                <MdOutlineKeyboardArrowRight className="arrows" size={24} />
                            </button>
							)}
						</div>
					</div>
			</div>
			<div className="case-details-gallery">
				<Slider {...slickSettings}>
					{project.photos.map((photo) => (
						<div
							key={`photo-${photo.id}`}
							className="case-details-gallery-slide"
						>
							<img
								className="case-details-gallery-slide-img"
								src={photo.url}
								alt={photo.id}
							/>
						</div>
					))}
				</Slider>
			</div>
		</section>
	);
};

export default CaseDetails;
