import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { EN } from './en/translations';
import { CN } from './cn/translations';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: EN
            },
            zh_CN: {
                translation: CN
            }
        }
    });

const defaultLang = localStorage.getItem('lang') || 'en';

i18n.changeLanguage(defaultLang);

export default i18n;