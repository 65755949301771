import './player.css';

interface Props {
    src: string;
}

const Player: React.FC<Props> = (props) => {
    const { src } = props;
	return (
		<>
			<section
				className='player-container'
			>
				<div
					style={{
						padding: `75% 0 0 0`,
						position: `relative`,
					}}
				>
					<iframe
						src={src}
						allow="autoplay; fullscreen; picture-in-picture"
						allowFullScreen
						className='player-iframe'
						title="NAK Portfolio.mp4"
					></iframe>
				</div>
				<script src="https://player.vimeo.com/api/player.js"></script>
			</section>
		</>
	);
};

export default Player;
