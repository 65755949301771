import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { Case } from './CaseType'

interface CasesState{
    cases: Case[]
}

const initialState: CasesState = {
    cases: []
}

export const casesSlice = createSlice({
    name: 'cases',
    initialState,
    reducers: {
        setCases: (state: CasesState, action: PayloadAction<Case[]>) => { 
            state.cases = action.payload
        }
    }
})

export const { setCases } = casesSlice.actions;
export const selectCases = (state: RootState) => state.cases.cases;
export const selectCase = (id: string) => (state: RootState) => state.cases.cases.find(item => item.id === id);
export const selectPrevNext = (id: string) => (state: RootState) => {
    const current: number = state.cases.cases.findIndex(item => item.id === id);
    if (current < 0) { return null };
    const prev: Case | null = current === 0 ? null : state.cases.cases[current - 1];
    const next: Case | null = current === state.cases.cases.length - 1 ? null : state.cases.cases[current + 1];
    return { prev, next };
}

export default casesSlice.reducer;